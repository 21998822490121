<template>
  <div
    ref="menuWrapper"
    :style="customColorTheme"
    :class="{
      'is-visible': isVisible,
      'top-[--globalHeaderHeightDesktop]': offset === 'globalHeader',
      'top-[--communityNavigationHeightDesktop]':
        offset === 'communityNavigation',
    }"
    :aria-expanded="!isVisible"
    class="c-mega-menu transition-visibility ease-out-expo absolute left-0 z-40 hidden w-full border-t py-16 duration-700 group-hover:block"
    @mouseleave="onMouseLeave"
  >
    <div class="container">
      <div class="grid grid-cols-12 gap-10">
        <div class="col-span-4 flex flex-col gap-y-6">
          <h2 v-if="label" class="c-mega-menu__title text-2xl font-bold">
            {{ label }}
          </h2>
          <p
            v-if="menu.description"
            class="c-mega-menu__description max-w-80 text-sm leading-relaxed"
          >
            {{ menu.description }}
          </p>
        </div>

        <ul
          v-for="(
            childNavigationMenu, childNavigationMenuIndex
          ) in menu.menuItems"
          :key="childNavigationMenu._uid"
          :aria-label="menu.label"
          :class="{
            'pt-4 text-lg font-bold': childNavigationMenuIndex === 0,
            'pt-5 text-sm': childNavigationMenuIndex === 1,
          }"
          role="menu"
          class="c-mega-menu__links col-span-4 flex flex-col gap-y-4"
        >
          <li
            v-for="childMenuItem in childNavigationMenu.menuItems"
            :key="childMenuItem._uid"
          >
            <StoryblokComponent
              :blok="childMenuItem"
              :custom-color-theme="customColorTheme"
              icon-size="relative"
              data-mega-menu-link="true"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BlockNavigationMenuWrapperStoryblok } from '@/types/storyblok'
import type { NavigationDesktopMegaMenuTheme } from './types'

const props = withDefaults(
  defineProps<{
    label?: string
    menu: BlockNavigationMenuWrapperStoryblok
    menuItemIndex: number
    offset?: 'globalHeader' | 'communityNavigation'
    customColorTheme?: NavigationDesktopMegaMenuTheme
  }>(),
  {
    label: undefined,
    offset: 'globalHeader',
    customColorTheme: undefined,
  },
)

const menuWrapper = ref<HTMLElement | null>(null)
const isVisible = ref<boolean>(false)

defineExpose({
  hide,
  isVisible,
  menuWrapper,
  show,
  toggle,
  menuItemIndex: props.menuItemIndex,
})

function show(): void {
  isVisible.value = true
}

function hide(): void {
  isVisible.value = false
}

function toggle(): void {
  isVisible.value = !isVisible.value
}

function onMouseLeave(): void {
  isVisible.value = false
}
</script>

<style scoped lang="postcss">
.c-mega-menu {
  background-color: var(--custom-theme-fill-color, theme('colors.white'));
  border-top-color: var(--custom-theme-border-color, theme('colors.gray.300'));

  &__title {
    color: var(--custom-theme-title-color, theme('colors.primary.500'));
  }

  &__description {
    color: var(--custom-theme-text-color, theme('colors.secondary'));
  }

  &__links {
    color: yellow;
    color: var(--custom-theme-text-color, theme('colors.gray.700'));
  }

  &.is-visible {
    @apply block;
  }
}
</style>

<style lang="postcss">
@media (pointer: coarse) {
  :not(.is-active) ~ .c-mega-menu {
    @apply !hidden;
  }

  .is-active ~ .c-mega-menu {
    @apply !block;
  }
}
</style>
