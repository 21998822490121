<template>
  <nav
    ref="globalNav"
    class="hidden h-full lg:flex"
    aria-label="Main Navigation"
  >
    <ul
      v-for="menu in headerNavigation"
      :key="menu._uid"
      role="menubar"
      class="flex items-center"
    >
      <li
        v-for="(menuItem, menuItemIndex) in removeMobileOnlyMenuItems(
          menu?.menuItems,
        )"
        :key="menuItem._uid"
        class="group h-full"
        role="none"
      >
        <StoryblokComponent
          :blok="menuItem"
          :data-has-mega-menu="!!menuItem.childMenu?.length"
          :data-menu-item-index="menuItemIndex"
          :is-desktop-navigation="true"
          aria-haspopup="true"
          icon-classes="c-global-nav-menu-icon ease-out-expo text-gray-300 transition-transform duration-700 group-hover:rotate-180"
          icon-size="md"
          class="flex h-full items-center gap-[2px] px-2 text-sm text-gray-900"
        />

        <NavigationDesktopMegaMenu
          v-for="childMenu in menuItem.childMenu"
          ref="megaMenuRefs"
          :key="childMenu._uid"
          :label="menuItem.label"
          :menu="childMenu"
          :menu-item-index="menuItemIndex"
          :custom-color-theme="{
            '--custom-theme-hover-text-color': colors.primary['500'],
          }"
          offset="globalHeader"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import colors from '#tailwind-config/theme/colors'
import type NavigationDesktopMegaMenu from '@/components/navigation/NavigationDesktopMegaMenu.vue'
import useMegaMenu from '@/composables/useMegaMenu'
import { removeMobileOnlyMenuItems } from '@/utils/common-utils'

const globalNav = ref<HTMLElement | null>(null)
const megaMenuRefs = ref<InstanceType<typeof NavigationDesktopMegaMenu>[]>([])

const { headerNavigation } = await useConfigStore().asyncConfig()

const { ready, setupEventListeners, removeEventListeners } =
  useMegaMenu(megaMenuRefs)

onMounted(() => {
  ready(globalNav.value)
  setupEventListeners()
})

onBeforeUnmount(() => {
  removeEventListeners()
})
</script>

<style lang="postcss">
@media (pointer: coarse) {
  .is-active > .c-global-nav-menu-icon {
    @apply !rotate-180;
  }

  :not(.is-active) > .c-global-nav-menu-icon {
    @apply !rotate-0;
  }
}
</style>
