import type { NavigationDesktopMegaMenu } from '#components'

type AccessibilityKey = 'ArrowDown' | 'ArrowUp' | 'Escape' | 'Space'

export default function (
  megaMenuRefs: Ref<InstanceType<typeof NavigationDesktopMegaMenu>[]>,
) {
  const menuElement = ref<HTMLElement | null>(null)
  const accessibilityKeys: AccessibilityKey[] = [
    'ArrowDown',
    'ArrowUp',
    'Escape',
    'Space',
  ]

  function ready(el: HTMLElement | null) {
    menuElement.value = el
  }

  function setupEventListeners() {
    menuElement.value?.parentElement?.addEventListener('keydown', onKeydown)
    menuElement.value?.parentElement?.addEventListener('keyup', onKeyup)
  }

  function removeEventListeners() {
    menuElement.value?.parentElement?.removeEventListener('keydown', onKeydown)
    menuElement.value?.parentElement?.removeEventListener('keyup', onKeyup)
  }

  function onKeyup(event: KeyboardEvent): void {
    if (event.code !== 'Tab') {
      return
    }

    const targetElement = event.target as HTMLElement
    const { hasMegaMenu, megaMenuLink } = targetElement.dataset
    const isOutsideOfMenu = !hasMegaMenu && !megaMenuLink
    const isTopLevelLink = hasMegaMenu && !megaMenuLink

    if (isTopLevelLink || isOutsideOfMenu) {
      hideAllMegaMenus()
    }
  }

  function onKeydown(event: KeyboardEvent): void {
    if (!accessibilityKeys.includes(event.code as AccessibilityKey)) {
      return
    }

    const { menuItemIndex } = (event.target as HTMLElement).dataset
    const megaMenuRef = menuItemIndex
      ? megaMenuRefs.value.find(
          (megaMenu) => megaMenu.menuItemIndex === parseInt(menuItemIndex),
        )
      : undefined

    if (megaMenuRef) {
      event.preventDefault()

      switch (event.code) {
        case 'ArrowDown':
          hideAllMegaMenus()
          megaMenuRef?.show()
          break
        case 'Space':
          megaMenuRef.toggle()
          break
        case 'ArrowUp':
        case 'Escape':
          megaMenuRef?.hide()
          break
      }
    }
  }

  function hideAllMegaMenus(): void {
    megaMenuRefs.value.forEach((megaMenuRef) => megaMenuRef.hide())
  }

  return {
    ready,
    setupEventListeners,
    removeEventListeners,
  }
}
